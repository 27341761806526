var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',{attrs:{"title":_vm.$route.name,"options":_vm.options}},[_c('v-flex',{staticClass:"pa-3",attrs:{"xs12":""}},[(_vm.$vuetify.breakpoint.lgAndUp)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left fonte"},[_vm._v(" NOME ")]),_c('th',{staticClass:"text-left fonte"},[_vm._v(" BANCO ")]),_c('th',{staticClass:"text-left fonte"},[_vm._v(" TIPO ")]),_c('th',{staticClass:"text-left fonte"},[_vm._v(" INFO CONTA ")]),_c('th',{staticClass:"text-left fonte"},[_vm._v(" VALOR ")]),_c('th',{staticClass:"text-left fonte"},[_vm._v(" DATA ")]),_c('th',{staticClass:"text-left fonte"},[_vm._v(" STATUS ")]),_c('th',{staticClass:"text-left fonte"},[_vm._v(" APROVAR ")]),_c('th',{staticClass:"text-left fonte"},[_vm._v(" CANCELAR ")])])]),_c('tbody',_vm._l((_vm.getRequestPayments),function(item){return _c('tr',{key:item._id},[_c('td',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.user.name))]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.withdraw_info ? item.withdraw_info.bank : "")+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("filterRequestPaymentType")(item.transaction_type))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.withdraw_info ? item.withdraw_info.info : "")+" ")]),_c('td',{staticClass:"font-weight-bold green--text"},[_vm._v("$ "+_vm._s(item.value))]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("DD/MM/YYYY [às] HH:mm"))+" ")]),_c('td',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.current_status === "active" ? "pago" : item.current_status)+" ")]),_c('td',{staticClass:"font-weight-bold"},[(
                  (item.current_status !== 'active' &&
                    item.current_status !== 'canceled') ||
                    item.current_status === 'aguardando pagamento'
                )?_c('v-btn',{staticClass:"fonte",attrs:{"small":"","outlined":"","color":_vm.$theme.primary},on:{"click":function($event){return _vm.createConfirmAction({
                    icon: 'mdi-check',
                    message: 'Aprovar Este Saque?',
                    action: 'approveRequestPayment',
                    action_value: item
                  })}}},[_vm._v(" aprovar ")]):_vm._e()],1),_c('td',{staticClass:"font-weight-bold"},[(
                  item.current_status === 'aguardando pagamento' &&
                    item.current_status !== 'active'
                )?_c('v-btn',{staticClass:"fonte",attrs:{"small":"","outlined":"","color":"red"},on:{"click":function($event){return _vm.createConfirmAction({
                    icon: 'mdi-check',
                    message: 'Cancelar Este Saque?',
                    action: 'cancelRequestPayment',
                    action_value: item
                  })}}},[_vm._v(" cancelar ")]):_vm._e()],1)])}),0)]},proxy:true}],null,false,572143425)}):_c('v-list',{staticClass:"pa-0 ma-0",staticStyle:{"border-radius":"6px"},attrs:{"dark":"","dense":"","three-line":""}},[_vm._l((_vm.getRequestPayments),function(item,index){return [_c('v-list-item',{key:item._id},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"fonte"},[_vm._v(" Cliente: "+_vm._s(item.user.name)+" ")]),_c('v-list-item-subtitle',{staticClass:"fonte"},[_vm._v(" Banco: "+_vm._s(item.withdraw_info ? item.withdraw_info.bank : "")+" - "+_vm._s(_vm._f("filterRequestPaymentType")(item.transaction_type))+" - "+_vm._s(item.withdraw_info ? item.withdraw_info.info : "")+" ")]),_c('v-list-item-subtitle',{staticClass:"fonte"},[_vm._v(" "+_vm._s(item.value)+" - "+_vm._s(item.current_status)+" ")]),_c('v-list-item-subtitle',{staticClass:"fonte"},[_vm._v(" "+_vm._s(_vm.$moment(item.created_at).format("DD/MM/YYYY [às] HH:mm"))+" ")]),_c('v-list-item-subtitle',{staticClass:"fonte"},[(item.current_status !== 'active')?_c('v-btn',{staticClass:"fonte",attrs:{"small":"","outlined":"","color":_vm.$theme.primary},on:{"click":function($event){return _vm.createConfirmAction({
                    icon: 'mdi-check',
                    message: 'Aprovar Este Saque?',
                    action: 'approveRequestPayment',
                    action_value: item
                  })}}},[_vm._v(" aprovar ")]):_vm._e(),(
                  item.current_status === 'aguardando pagamento' &&
                    item.current_status !== 'active'
                )?_c('v-btn',{staticClass:"fonte ml-2",attrs:{"small":"","outlined":"","color":"red"},on:{"click":function($event){return _vm.createConfirmAction({
                    icon: 'mdi-check',
                    message: 'Cancelar Este Saque?',
                    action: 'cancelRequestPayment',
                    action_value: item
                  })}}},[_vm._v(" cancelar ")]):_vm._e()],1)],1),_c('v-list-item-action',[(item.current_status !== 'active')?_c('v-btn',{staticClass:"fonte",attrs:{"small":"","outlined":"","color":_vm.$theme.primary},on:{"click":function($event){return _vm.createConfirmAction({
                  icon: 'mdi-check',
                  message: 'Aprovar Este Saque?',
                  action: 'approveRequestPayment',
                  action_value: item
                })}}},[_vm._v(" aprovar ")]):_vm._e(),(
                item.current_status === 'aguardando pagamento' &&
                  item.current_status !== 'active'
              )?_c('v-btn',{staticClass:"fonte",attrs:{"small":"","outlined":"","color":"red"},on:{"click":function($event){return _vm.createConfirmAction({
                  icon: 'mdi-check',
                  message: 'Cancelar Este Saque?',
                  action: 'cancelRequestPayment',
                  action_value: item
                })}}},[_vm._v(" cancelar ")]):_vm._e()],1)],1),_c('v-divider',{key:index})]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }