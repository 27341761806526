<template>
  <layout :title="$route.name" :options="options">
    <v-flex xs12 class="pa-3">
      <v-simple-table v-if="$vuetify.breakpoint.lgAndUp" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left fonte">
                NOME
              </th>
              <th class="text-left fonte">
                BANCO
              </th>
              <th class="text-left fonte">
                TIPO
              </th>
              <th class="text-left fonte">
                INFO CONTA
              </th>
              <th class="text-left fonte">
                VALOR
              </th>
              <th class="text-left fonte">
                DATA
              </th>
              <th class="text-left fonte">
                STATUS
              </th>
              <th class="text-left fonte">
                APROVAR
              </th>
              <th class="text-left fonte">
                CANCELAR
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in getRequestPayments" :key="item._id">
              <td class="font-weight-bold">{{ item.user.name }}</td>
              <td class="font-weight-bold">
                {{ item.withdraw_info ? item.withdraw_info.bank : "" }}
              </td>
              <td class="font-weight-bold">
                {{ item.transaction_type | filterRequestPaymentType }}
              </td>
              <td class="font-weight-bold">
                {{ item.withdraw_info ? item.withdraw_info.info : "" }}
              </td>
              <td class="font-weight-bold green--text">$ {{ item.value }}</td>
              <td class="font-weight-bold">
                {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
              </td>
              <td class="font-weight-bold">
                {{
                  item.current_status === "active"
                    ? "pago"
                    : item.current_status
                }}
              </td>
              <td class="font-weight-bold">
                <v-btn
                  @click="
                    createConfirmAction({
                      icon: 'mdi-check',
                      message: 'Aprovar Este Saque?',
                      action: 'approveRequestPayment',
                      action_value: item
                    })
                  "
                  v-if="
                    (item.current_status !== 'active' &&
                      item.current_status !== 'canceled') ||
                      item.current_status === 'aguardando pagamento'
                  "
                  small
                  class="fonte"
                  outlined
                  :color="$theme.primary"
                >
                  aprovar
                </v-btn>
              </td>

              <td class="font-weight-bold">
                <v-btn
                  @click="
                    createConfirmAction({
                      icon: 'mdi-check',
                      message: 'Cancelar Este Saque?',
                      action: 'cancelRequestPayment',
                      action_value: item
                    })
                  "
                  v-if="
                    item.current_status === 'aguardando pagamento' &&
                      item.current_status !== 'active'
                  "
                  small
                  class="fonte"
                  outlined
                  color="red"
                >
                  cancelar
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-list
        v-else
        dark
        style="border-radius: 6px;"
        class="pa-0 ma-0"
        dense
        three-line
      >
        <template v-for="(item, index) in getRequestPayments">
          <v-list-item :key="item._id">
            <v-list-item-content>
              <v-list-item-title class="fonte">
                Cliente: {{ item.user.name }}
              </v-list-item-title>
              <v-list-item-subtitle class="fonte">
                Banco: {{ item.withdraw_info ? item.withdraw_info.bank : "" }} -
                {{ item.transaction_type | filterRequestPaymentType }} -
                {{ item.withdraw_info ? item.withdraw_info.info : "" }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="fonte">
                {{ item.value }} - {{ item.current_status }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="fonte">
                {{ $moment(item.created_at).format("DD/MM/YYYY [às] HH:mm") }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="fonte">
                <v-btn
                  @click="
                    createConfirmAction({
                      icon: 'mdi-check',
                      message: 'Aprovar Este Saque?',
                      action: 'approveRequestPayment',
                      action_value: item
                    })
                  "
                  v-if="item.current_status !== 'active'"
                  small
                  class="fonte"
                  outlined
                  :color="$theme.primary"
                >
                  aprovar
                </v-btn>

                <v-btn
                  @click="
                    createConfirmAction({
                      icon: 'mdi-check',
                      message: 'Cancelar Este Saque?',
                      action: 'cancelRequestPayment',
                      action_value: item
                    })
                  "
                  v-if="
                    item.current_status === 'aguardando pagamento' &&
                      item.current_status !== 'active'
                  "
                  small
                  class="fonte ml-2"
                  outlined
                  color="red"
                >
                  cancelar
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                @click="
                  createConfirmAction({
                    icon: 'mdi-check',
                    message: 'Aprovar Este Saque?',
                    action: 'approveRequestPayment',
                    action_value: item
                  })
                "
                v-if="item.current_status !== 'active'"
                small
                class="fonte"
                outlined
                :color="$theme.primary"
              >
                aprovar
              </v-btn>
              <v-btn
                @click="
                  createConfirmAction({
                    icon: 'mdi-check',
                    message: 'Cancelar Este Saque?',
                    action: 'cancelRequestPayment',
                    action_value: item
                  })
                "
                v-if="
                  item.current_status === 'aguardando pagamento' &&
                    item.current_status !== 'active'
                "
                small
                class="fonte"
                outlined
                color="red"
              >
                cancelar
              </v-btn>
            </v-list-item-action>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-flex>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      options: []
    };
  },
  filters: {
    filterRequestPaymentType(val) {
      const types = {
        unilevel: "REDE",
        investment: "INVESTIMENTO"
      };
      return types[val];
    }
  },
  computed: {
    ...mapGetters(["getRequestPayments"])
  },
  methods: {
    ...mapActions(["listRequestPayments", "createConfirmAction"])
  },
  created() {
    this.listRequestPayments();
  }
};
</script>
